import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import smoothScroll from "./../smoothScroll";

gsap.registerPlugin(ScrollTrigger);

const contactsAnim = {
  commonEffects: {},
  initLoad() {
    if ($("#contacts").length == 0) return;

    contactsAnim.commonEffects = require("./../commonEffects");
    contactsAnim.groupAnims();
  },
  groupAnims() {
    contactsAnim.sectionHero();
    contactsAnim.sectionTimetables();
    contactsAnim.sectionWorld();
  },
  sectionHero() {
    gsap
      .timeline()
      .addLabel("start")
      .fromTo(
        ".hero__image-wrapper",
        contactsAnim.commonEffects.images.toMiddle,
        contactsAnim.commonEffects.images.to,
        "start+=.5"
      )
      .fromTo(
        ".hero__title ",
        contactsAnim.commonEffects.bigTitle.set,
        contactsAnim.commonEffects.bigTitle.to,
        "start+=1"
      )
      .fromTo(
        ".hero__content h2, .hero__content p",
        contactsAnim.commonEffects.textContent.set,
        contactsAnim.commonEffects.textContent.to,
        "start+=.75"
      )
      .fromTo(
        ".hero__content .link-wrapper",
        contactsAnim.commonEffects.textContent.set,
        contactsAnim.commonEffects.textContent.to,
        "start+=.5"
      )
  },
  sectionFormContent() {
    gsap
      .timeline({
        scrollTrigger: {
          trigger: ".form-content__wrapper",
          start: "top bottom",
          scrub: true,
          markers: false,
        },
      })
      .fromTo(
        ".form-content__title",
        contactsAnim.commonEffects.bigTitle.set,
        contactsAnim.commonEffects.bigTitle.to
      );
  },
  sectionTimetables() {
    gsap
      .timeline({
        scrollTrigger: {
          trigger: ".timetables__wrapper",
          start: "top center",
          end: "+=50%",
          markers: false,
          scrub: true,
        },
      })
      .fromTo(
        ".timetables__timetable ",
        contactsAnim.commonEffects.textContent.set,
        contactsAnim.commonEffects.textContent.to
      )
      .fromTo(
        ".timetables__title",
        contactsAnim.commonEffects.bigTitle.set,
        contactsAnim.commonEffects.bigTitle.to,
        "-=1"
      );
  },

  sectionWorld() {
    gsap
      .timeline({
        scrollTrigger: {
          trigger: ".world__wrapper",
          start: "top center",
          end: '+=500',
          markers: false,
          scrub: true,
        },
      })
      .fromTo(
        ".world__wrapper .text-columns__title",
        contactsAnim.commonEffects.bigTitle.set,
        contactsAnim.commonEffects.bigTitle.to
      );
  },
};

export default contactsAnim;
