import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import smoothScroll from "./smoothScroll";

gsap.registerPlugin(ScrollTrigger);

const utils = {
  initLoad() {
    utils.resizeWindow();
    utils.openTextContent();
    utils.openTextTerritory();
    utils.footerColor();
    utils.initVideo();
  },
  initVideo() {
    const $el = $("#video");
    if ($el.length) {

      // check if exist data-src-desktop -> or just src
      let src = $el.data("src-desktop") ||$el.data("src");
      if ($(window).outerWidth() < 767) src = $el.data("src-mobile") || $el.data("src");

      $el.find("source").attr("src", src);
      $el.attr('preload', 'auto')
      $el[0].load();
      // $('.top-home').addClass('play')
    }
  },
  resizeWindow() {
    if ($(window).outerWidth() > 767) return;

    const elsH = ["#preloader", "#home .hero", "#home .wines-slider"];
    elsH.forEach((el) => {
      if ($(el).length > 0) $(el).css("height", $(window).outerHeight());
    });

    const elsMH = [".header__menu"];
    elsMH.forEach((el) => {
      if ($(el).length > 0) $(el).css("min-height", $(window).outerHeight());
    });
  },
  openTextContent() {
    if ($(".js-will-open").length == 0) return;
    $(".js-will-open").on("click", function () {
      let $cont = $(this);
      gsap.timeline({
        onComplete: function () {
          // $cont.find(".link-simple").hide()
          $cont.find(".hidden").slideToggle();
          $cont.find(".arrow-wrapper svg").toggleClass("is-rotated");

          let textContent = $cont.find(".text-wrapper span");
          if ($cont.find(".arrow-wrapper svg").hasClass("is-rotated")) {
            textContent.text($cont.find(".arrow-wrapper").data("label-close"));
          } else {
            $cont;
            textContent.text($cont.find(".arrow-wrapper").data("label-open"));
          }

          smoothScroll.updatePositions();
        },
      });
      // .to($cont.find(".link-simple"), { opacity: 0 })
    });
  },
  openTextTerritory() {
    if ($(".open-text-row").length == 0) return;
    $(".open-text-row .open-text").on("click", function () {
      let $cont = $(this);
      gsap.timeline({
        onComplete: function () {
          $cont.find(".hidden").slideToggle();
          $cont.find(".visible svg").toggle();
          smoothScroll.updatePositions();
        },
      });
    });
  },
  footerColor() {
    const elsH = ["#wines", "#single-wine"];
    elsH.forEach((el) => {
      if ($(el).length > 0) {
        $(".footer").css("background", "#5D5654");
        $(".footer .link-circle").css("background", "#BBB8B4");
      }
    });
  },
	is_touch_device() {
		const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
		const mq = function (query) {
			return window.matchMedia(query).matches;
		};

		if (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch) {
			return true;
		}

		// include the 'heartz' as a way to have a non matching MQ to help terminate the join
		// https://git.io/vznFH
		const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
		return mq(query);
	},
};

export default utils;
