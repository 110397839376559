import smoothScroll from "./smoothScroll";
const news = {
  initLoad() {
    news.newsLink();
    news.clickNews();
    // news.resizeNews();
  },
  newsLink() {
    if ($(window).outerWidth() < 769) {
      //  remove links

      // home or other pages
      $(".news-block").each(function (index, news) {
        let newLink = $(this).find(".goToPage").data("link");
        //  remove links
        $(this).find(".goToPage").attr("href", newLink);
        $(this).find(".link-simple").attr("href", newLink);
      });
    } else {
      // news page
      if ($("#news").length > 0) {
        $("#news .news-block__wrapper .link-simple,#news .goToPage").attr("href", "javascript:;");
      }
    }
  },
  clickNews() {
    if ($("#news").length == 0) return;

    // mobile versione takes us to news single page
    // desktop version opens up a single news section in the same page
    if ($(window).outerWidth() < 991) return;

    if (window.location.hash) {
      let target = window.location.hash;
      if ($(target)) {
        news.openNews($(target));
      }
    }

    // opens up
    $(".news-block").on("click", function () {
      news.openNews($(this));
    });



  },
  openNews(selectedNews) {

    // hide previous
    news.closeNews();

    // find data
    let newTitle = selectedNews
      .find(".news-block__hidden-content .title")
      .text();
    let newDate = selectedNews.find(".news-block__hidden-content .date").text();
    let newContent = selectedNews
      .find(".news-block__hidden-content .content")
      .html();
    let newImage = selectedNews
      .find(".news-block__hidden-content .image")
      .attr("src");
    let singleNewsContent = selectedNews.nextAll(".single-news-content").eq(0);
    let singleNewsLinks = selectedNews.nextAll(".single-news-links").eq(0);

    // close
    $(".single-news-content .close").on("click", function () {
      news.closeNews();
    });
    singleNewsContent.find('.title').on("click", function () {
      news.closeNews();
    });

    $(".news-block").removeClass("opened");
    selectedNews.addClass("opened");

    // update data
    singleNewsContent.toggleClass("opened");
    singleNewsContent.find(".title").text(newTitle);
    singleNewsContent.find(".date").text(newDate);
    singleNewsContent.find(".content").html(newContent);
    singleNewsContent.find(".image").attr("src", newImage);

    // scroll
    smoothScroll.scrollTo(
      0,
      document.querySelector(".single-news-content.opened").offsetTop - 250
    );
  },
  closeNews() {

    let openedNews = document.querySelector(".news-block.opened")
    if (openedNews) smoothScroll.scrollTo(0,openedNews.offsetTop - 250);
    $(".single-news-content.opened").removeClass("opened");
  },
};

export default news;
