import smoothState from "smoothState";
import { gsap } from "gsap";
import smoothScroll from "./smoothScroll";

const pageTransition = {
  transitioner: $("#transitioner"),
  initReady() {
    this.initPageTransition();
  },

  initPageTransition() {
    const options = {
      anchors: "a",
      forms: "form",
      allowFormCaching: false,
      scroll: true,
      blacklist: ".no-smoothState , .no-smoothState > a, .wpcf7-form",
      prefetch: false,
      cacheLength: 0,
      onBefore: function ($currentTarget, $container) {
        $('#cursor').attr('class', 'active loading')

        // overlay (SVG path element)
        const overlayPath = document.querySelector(".overlay__path");

        // page transitioner
        // gsap.fromTo(pageTransition.transitioner, {  display: 'none',yPercent: 100 },{ display: 'block', yPercent: 0 });
        gsap
          .timeline()
          .set(overlayPath, {
            attr: { d: "M 0 100 V 100 Q 50 100 100 100 V 100 z" },
          })
          .to(
            overlayPath,
            {
              duration: 0.8,
              ease: "power4.in",
              attr: { d: "M 0 100 V 50 Q 50 0 100 50 V 100 z" },
            },
            0
          )
          .to(overlayPath, {
            duration: 0.3,
            ease: "power2",
            attr: { d: "M 0 100 V 0 Q 50 0 100 0 V 100 z" },
            onComplete: () => {
              // frame.classList.add("frame--menu-open");
              // menuWrap.classList.add("menu-wrap--open");
            },
          });
      },
      onStart: {
        duration: 400,
        render: function ($container) {
          if ($(".header__hamburger").hasClass("opened")) {
            $(".header__hamburger, .header, .header__menu").removeClass(
              "opened"
            );
            setTimeout(function () {
              // $("#main").addClass("is-exiting");
            }, 200);
          } else {
            // $("#main").addClass("is-exiting");
          }
        },
      },
      onReady: {
        render: function ($container, $newContent) {
          $container.html($newContent);
        },
      },
      onAfter: function ($container, $newContent) {
        $(document).trigger("rebuild");

        $('#cursor').removeClass('loading')

        smoothScroll.logoHasBeenAnim = false;

        pageTransition.checkUrls();

        // update body class
        $("body").attr("class", $("#bodydata").attr("class"));
        var cf_selector = "div.wpcf7 > form";
        var cf_forms = $newContent.find(cf_selector);
        if (cf_forms.length > 0) {
          $(cf_selector).each(function () {
            var $form = $(this);
            wpcf7.init($form[0]);
          });
        }

        setTimeout(function () {
          // page transitioner
          // setTimeout(() => {gsap.fromTo(pageTransition.transitioner, { yPercent: 0 },{ yPercent: -100 })	}, 700);

          // overlay (SVG path element)
          const overlayPath = document.querySelector(".overlay__path");

          gsap
            .timeline()
            .set(overlayPath, {
              attr: { d: "M 0 0 V 100 Q 50 100 100 100 V 0 z" },
            })
            .to(overlayPath, {
              duration: 0.3,
              ease: "power2.in",
              attr: { d: "M 0 0 V 50 Q 50 0 100 50 V 0 z" },
            })
            .to(overlayPath, {
              duration: 0.8,
              ease: "power4",
              attr: { d: "M 0 0 V 0 Q 50 0 100 0 V 0 z" },
            });
          // $("#main").removeClass("is-exiting");
        }, 350);
      },
    };

    $("#main").smoothState(options).data("smoothState");
  },
  checkUrls() {
    if (window.location.hash) {
      let target = window.location.hash;

      smoothScroll.scrollTo(0, document.querySelector(target).offsetTop);
    }
  },
};

export default pageTransition;
