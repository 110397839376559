import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/all";
import smoothScroll from "./smoothScroll";

//custom
import homeAnim from "./effects/home";
import flexbileAnim from "./effects/flexible";
import winesAnim from "./effects/wines";
import singleWineAnim from "./effects/singleWine";
import hospitalityAnim from "./effects/hospitality";
import newsAnim from "./effects/news";
import contactsAnim from "./effects/contacts";
import effectbigTitle from "./effects/bigTitles";

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

const animations = {

  initLoad() {
    animations.syncSmooth();
    animations.importAnims();
    animations.footerArrow();
    animations.fixMarkers();
  },
  configAnims() {
    const configs = require("./animConfigs");

    configs.forEach((config) => {
      let configClass = `.${config.class}`;
      let configSet = config.set;
      let configStart = config.start;
      let configMarkers = config.markers;
      let configeffect = config.effect;

      if ($(configClass).length > 0) {
        let els = gsap.utils.toArray(configClass);

        // make scrolltrigger
        els.forEach(function (el) {
          let dontUseTrigger = el.hasAttribute("data-trigger");

          gsap.set(el, configSet);
          if (dontUseTrigger){
            gsap.timeline().to($(configClass), configeffect);

          } else {
            gsap
              .timeline({
                scrollTrigger: {
                  trigger: el,
                  scrub: true,
                  start: configStart,
                  markers: configMarkers,
                  duration: 5,
                },
              })
              .to(el, configeffect);
          }

        });
      }

    });
  },
  syncSmooth() {
    ScrollTrigger.scrollerProxy("#main_cont", {
      scrollTop(value) {
        if (arguments.length) {
          smoothScroll.bodyScrollBar.scrollTop = value;
        }
        return smoothScroll.bodyScrollBar.scrollTop;
      },
    });

    smoothScroll.bodyScrollBar.addListener(ScrollTrigger.update);

    ScrollTrigger.defaults({ scroller: smoothScroll.scroller });

  },
  fixMarkers(){

    if (document.querySelector('.gsap-marker-scroller-start')) {
      const markers = gsap.utils.toArray('[class *= "gsap-marker"]');

      smoothScroll.bodyScrollBar.addListener(({ offset }) => {
        gsap.set(markers, { marginTop: -offset.y })
      });
    }

  },
  footerArrow() {
    $("footer .link-circle").on("click", function () {
      smoothScroll.scrollTo(0,0)
    });
  },
  importAnims() {


    // update positions after all animations
    homeAnim.initLoad();
    flexbileAnim.initLoad();
    winesAnim.initLoad();
    singleWineAnim.initLoad();
    hospitalityAnim.initLoad();
    newsAnim.initLoad();
    contactsAnim.initLoad();
    effectbigTitle.initLoad();
  },
  isMobile() {
    return $(window).outerWidth() < 991;
  }
};

module.exports = animations;
