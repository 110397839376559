import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const newsAnim = {
  commonEffects: {},
  initLoad() {
    if ($("#news").length == 0) return;

    newsAnim.commonEffects = require("./../commonEffects");
    newsAnim.groupAnims();
  },
  groupAnims() {
    newsAnim.sectionList();
  },
  sectionList() {
    // first 3 items
    let firstNews = gsap.utils.toArray(".news-block").slice(0, 3);
    gsap
      .timeline()
      .addLabel("start")
      .fromTo(
        ".list__title ",
        newsAnim.commonEffects.bigTitle.set,
        newsAnim.commonEffects.bigTitle.to,
        "start+=.5"
      )
      .fromTo(
        firstNews,
        newsAnim.commonEffects.textContent.set,
        newsAnim.commonEffects.textContent.to,
        'start+=0.8'
      );

    // remaining items
    let lastNews = gsap.utils.toArray(".news-block").slice(3);
    gsap.set(lastNews, newsAnim.commonEffects.textContent.set);
    lastNews.forEach((product, index) => {
      gsap
        .timeline({
          scrollTrigger: {
            trigger: product,
            start: "top bottom",
            end: "+=500px",
            markers: false,
            scrub: false,
          },
        })
        .to(product, newsAnim.commonEffects.textContent.to);
    });
  },
};

export default newsAnim;
