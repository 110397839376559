import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import smoothScroll from "./../smoothScroll";

gsap.registerPlugin(ScrollTrigger);

const flexibileAnim = {
  commonEffects: {},
  initLoad() {
    if ($("#flexible").length == 0) return;

    flexibileAnim.commonEffects = require("./../commonEffects");
    flexibileAnim.groupAnims();
  },
  groupAnims() {
    flexibileAnim.sectionHero();
    flexibileAnim.sectionIntroduction();
    flexibileAnim.sectionGrid();
    flexibileAnim.sectionBigImage();
    flexibileAnim.sectionTextContent();
    flexibileAnim.sectionSlider();
  },
  sectionHero() {


    gsap
      .timeline()
      .addLabel("start")
      .fromTo(".top-image__image", { opacity: 0 }, {  opacity: 1 }, "start+=1")
      .fromTo(".top-image__image", { scale:1.2}, { scale:1, duration: .7 }, "start+=1.1")
      .fromTo(
        ".top-image__image-wrapper",
        flexibileAnim.commonEffects.images.set,
        flexibileAnim.commonEffects.images.toMiddle,
        "start+=1"
      )
      .fromTo(
        ".top-image__title ",
        flexibileAnim.commonEffects.bigTitle.set,
        flexibileAnim.commonEffects.bigTitle.to,
        "start+=.8"
      );

    gsap
      .timeline({
        scrollTrigger: {
          trigger: ".top-image__image-wrapper",
          start: "top 25%",
          end: "+=35%",
          scrub: true,
        },
      })
      .to(".top-image__image-wrapper", flexibileAnim.commonEffects.images.to);
  },
  sectionIntroduction() {
    gsap
      .timeline({
        scrollTrigger: {
          trigger: ".introduction__wrapper",
          start: "top bottom",
          end: "+=500px",
          scrub: true,
        },
      })
      .fromTo(
        ".introduction__col",
        flexibileAnim.commonEffects.textContent.set,
        flexibileAnim.commonEffects.textContent.to
      );
  },
  sectionGrid() {
    let photoGridThreeSet = $(".photo-grid").hasClass("has-title")
      ? { yPercent: 0 }
      : { yPercent: -20 };
    let photoGridThreeTo = $(".photo-grid").hasClass("has-title")
      ? { yPercent: -50 }
      : { yPercent: -80 };

    gsap
      .timeline({
        scrollTrigger: {
          trigger: ".photo-grid__wrapper",
          start: "top bottom",
          markers: false,
          scrub: true,
        },
      })
      .addLabel("start")
      .fromTo(
        ".image-wrapper:first-child",
        flexibileAnim.commonEffects.photoGrid.set,
        flexibileAnim.commonEffects.photoGrid.one.to,
        "start"
      )
      .fromTo(
        ".image-wrapper:nth-child(2)",
        flexibileAnim.commonEffects.photoGrid.set,
        flexibileAnim.commonEffects.photoGrid.two.to,
        "start+=.1"
      )
      .fromTo(
        ".image-wrapper:last-child",
        photoGridThreeSet,
        photoGridThreeTo,
        "start+=.5"
      );
  },
  sectionBigImage() {
    gsap
      .timeline({
        scrollTrigger: {
          trigger: ".big-image",
          start: "top bottom",
          markers: false,
          scrub: true,
        },
      })
      .fromTo(
        ".big-image",
        flexibileAnim.commonEffects.images.toMiddle,
        flexibileAnim.commonEffects.images.to
      );
  },
  sectionTextContent() {


    let startAt = ($(window).outerWidth() > 767) ?  "top bottom" : "top center"
    gsap
      .timeline({
        scrollTrigger: {
          trigger: ".text-columns__wrapper",
          start: startAt,
          end: "+=500px",
          scrub: true,
        },
      })
      .fromTo(
        ".text-columns__col ",
        flexibileAnim.commonEffects.textContent.set,
        flexibileAnim.commonEffects.textContent.to
      );
  },
  sectionSlider() {
    gsap
      .timeline({
        scrollTrigger: {
          trigger: ".slider__wrapper",
          start: "top bottom",
          markers: false,
        },
      })
      .fromTo(
        ".slider__slide:first-child",
        flexibileAnim.commonEffects.images.toMiddle,
        flexibileAnim.commonEffects.images.to
      );
  },
};

export default flexibileAnim;
