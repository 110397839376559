import Scrollbar from "smooth-scrollbar";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { SplitText } from "gsap/SplitText";

const smoothScroll = {
  bodyScrollBar: "",
  scroller: "",
  logoHasBeenAnim: false,
  initLoad() {
    smoothScroll.initSmooth();
    smoothScroll.listenScroll();
  },
  initSmooth() {
    smoothScroll.scroller = document.querySelector("#main_cont");

    // if ($(window).outerWidth() < 767) deviceDamiping = 0.3
    smoothScroll.bodyScrollBar = Scrollbar.init(smoothScroll.scroller, {
      // damping: deviceDamiping,
      // delegateTo: document,
      alwaysShowTracks: false
    });
  },
  listenScroll() {
    smoothScroll.bodyScrollBar.addListener((status) => {
      let windowY = status.offset.y;

      smoothScroll.menuSticky(windowY);
    });
  },
  update() {
    smoothScroll.bodyScrollBar.update();
  },
  scrollTo(x = 0, y = 0, duration = 1000) {
    smoothScroll.bodyScrollBar.scrollTo(x, y, duration);
  },
  updatePositions() {
    ScrollTrigger.refresh();
    // ScrollTrigger.update();
  },
  menuSticky(windowY) {
    if ($(window).outerWidth() < 991 && $("#home").length == 0) return;


    if (windowY > 100) {
      $('.header').addClass('sticky')
    } else {
      $('.header').removeClass('sticky')

    }

    // 25% of page
    // if (windowY > 100) {
    //   if (smoothScroll.logoHasBeenAnim == false) {
    //     gsap
    //       .timeline({
    //         onStart: function () {
    //           smoothScroll.logoHasBeenAnim = true;
    //         },
    //         onComplete: function () {
    //           smoothScroll.logoHasBeenAnim = false;
    //         },
    //       })
    //       .to($(".header__logo svg #stemma"), {
    //         duration: 0.5,
    //         opacity: 0,
    //       })
    //       .to($(".header__logo svg #logo-letters"), {
    //         duration: 0.5,
    //         transform: "translateY(0)",
    //       })
    //       .to($(".header__logo svg #stemma"), {
    //         display: "none",
    //       });
    //   }
    // } else {
    //   if (smoothScroll.logoHasBeenAnim == false) {
    //     gsap
    //       .timeline({
    //         onStart: function () {
    //           smoothScroll.logoHasBeenAnim = true;
    //         },
    //         onComplete: function () {
    //           smoothScroll.logoHasBeenAnim = false;
    //         },
    //       })
    //       .to($(".header__logo svg #logo-letters"), {
    //         duration: 0.5,
    //         transform: "translateY(103px)",
    //       })
    //       .to($(".header__logo svg #stemma"), {
    //         display: " block",
    //       })
    //       .to($(".header__logo svg #stemma"), {
    //         duration: 0.5,
    //         opacity: 1,
    //       });
    //   }
    // }
  },
};

export default smoothScroll;
