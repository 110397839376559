import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const hospitalityAnim = {
  commonEffects: {},
  initLoad() {
    if ($("#hospitality").length == 0) return;

    hospitalityAnim.commonEffects = require("./../commonEffects");
    hospitalityAnim.groupAnims();
  },
  groupAnims() {
    hospitalityAnim.sectionHero();
    hospitalityAnim.sectionTextContent();
    hospitalityAnim.sectionTimetables();
    hospitalityAnim.sectionPacks();
  },
  sectionHero() {
    gsap
      .timeline()
      .addLabel("start")
      .fromTo(
        ".top-image__image",
        {opacity:0},
        {opacity:1},
        "start+=1"
      )
      .fromTo(".top-image__image", { scale:1.2}, { scale:1, duration: .7 }, "start+=1.1")
      .fromTo(
        ".top-image__image-wrapper",
        hospitalityAnim.commonEffects.images.set,
        hospitalityAnim.commonEffects.images.toMiddle,
        "start+=1"
      )
      .fromTo(
        ".top-image__title ",
        hospitalityAnim.commonEffects.bigTitle.set,
        hospitalityAnim.commonEffects.bigTitle.to,
        "start+=.8"
      );


      gsap
      .timeline({
        scrollTrigger: {
          trigger: ".top-image__image-wrapper",
          start: "top 25%",
          end: "+=35%",
          scrub: true,
        },
      })
      .to(".top-image__image-wrapper", hospitalityAnim.commonEffects.images.to);

  },
  sectionTextContent() {
    gsap
      .timeline({
        scrollTrigger: {
          trigger: ".text-columns__wrapper",
          end: "+=100%0",
          start: "top bottom",
          scrub: true,
        },
      })
      .fromTo(
        ".text-columns__col ",
        hospitalityAnim.commonEffects.textContent.set,
        hospitalityAnim.commonEffects.textContent.to
      );
  },
  sectionTimetables() {
    gsap
      .timeline({
        scrollTrigger: {
          trigger: ".timetables__wrapper",
          start: "top bottom",
          end: "bottom bottom",
          markers: false,
          scrub: true,
        },
      })
      .fromTo(
        ".timetables__image-wrapper img",
        hospitalityAnim.commonEffects.images.toMiddle,
        hospitalityAnim.commonEffects.images.to
      )
      .fromTo(
        ".timetables__box",
        hospitalityAnim.commonEffects.textContent.set,
        hospitalityAnim.commonEffects.textContent.to
      );
  },
  sectionPacks() {
    gsap
      .timeline({
        scrollTrigger: {
          trigger: ".packs__wrapper",
          start: "top bottom",
          end: "bottom bottom",
          scrub: true,
        },
      })
      .fromTo(
        ".packs__pack",
        hospitalityAnim.commonEffects.squareBox.set,
        hospitalityAnim.commonEffects.squareBox.to
      );
  },
};

export default hospitalityAnim;
