import { gsap } from "gsap";
import smoothScroll from "./smoothScroll";

const contacts = {
  initLoad() {
    contacts.continentList();
  },
  continentList() {
    if ($("#contacts").length == 0) return;

    $(".map-wrapper__inner .continent").on("mouseover", function () {
      let continent = $(this).attr("id");
      let btn = $("#btn-" + continent).find(".link-simple");

      $(".map-wrapper__inner .link-simple").removeClass("hovered");
      btn.addClass("hovered");
    });

    $('.map-wrapper__inner [id^="btn-"]').on("mouseover", function () {
      let continent = $(this)
        .attr("id")
        .substring(4, $(this).attr("id").length);

      $(".map-wrapper__inner .continent").removeClass("hovered");
      $(".map-wrapper__inner .continent#" + continent).addClass("hovered");
    });

    $(".map-wrapper__inner .continent").on("mouseleave", function () {
      $(this).removeClass("hovered");
      $(".map-wrapper__inner .link-simple").removeClass("hovered");
    });
    $('.map-wrapper__inner [id^="btn-"]').on("mouseleave", function () {
      let continent = $(this)
        .attr("id")
        .substring(4, $(this).attr("id").length);
      $(this).removeClass("hovered");
      $(".map-wrapper__inner .continent#" + continent).removeClass("hovered");
    });
  },
};

export default contacts;
