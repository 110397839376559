import $ from 'jquery'
import '../sass/main.scss';
import menu from './menu';
import lazyLoad from './lazyLoad';
import smoothState from './smoothState';
import animations from './animations';
import preloader from './preloader';
import slider from './slider';
import cursor from './cursor';
import contacts from './contacts';
import news from './news';
import importers from './importers';
import singleWine from './singleWine';
import circleLinks from './circleLinks';
import smoothScroll from './smoothScroll';
import utils from './utils';


window.$ = require('jquery');
window.jQuery = require('jquery');

const siteGlobal = {
  initReady() {
    smoothState.initReady();
    preloader.initReady();
    if(!utils.is_touch_device()) cursor.initReady()
    else cursor.destroy();
  },
  initLoad() {
    smoothScroll.initLoad();
  	lazyLoad.initLoad();
    menu.initLoad();
    slider.initLoad();
    news.initLoad();
    contacts.initLoad();
    singleWine.initLoad()
    circleLinks.initLoad()
    importers.initLoad();
    animations.initLoad();
    utils.initLoad();
    smoothScroll.updatePositions()
  },
  rebuildAllEvents() {
    smoothScroll.initLoad();
  	lazyLoad.initLoad();
    menu.initLoad();
    slider.initLoad();
    news.initLoad();
    contacts.initLoad();
    singleWine.initLoad()
    circleLinks.initLoad()
    importers.initLoad();
    animations.initLoad();
    utils.initLoad();
    smoothScroll.updatePositions()
  },
  initScroll( ){
  },
  initResize() {
  },
  rebuild() {
    siteGlobal.rebuildAllEvents();
  },
};

$(document).ready(() => {
  siteGlobal.initReady();
});

$(document).on('rebuild', () => {
  siteGlobal.rebuild();
});

$(window).on('load', () => {
  siteGlobal.initLoad();
});

$(window).on('resize', () => {
  siteGlobal.initResize();
});

$(window).on('scroll', () => {
  siteGlobal.initScroll();
});


