import "slick-carousel";

const slider = {
  arrow:
    '<svg width="72px" height="72px" viewBox="0 0 72 72" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"> <title>Group 12 Copy 2</title> <g id="HOMEPAGE" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"> <g id="MARVEL---HOME-1" transform="translate(-1789.000000, -5746.000000)"> <g id="Group-2" transform="translate(1.000000, 5279.000000)"> <g id="Group-12-Copy-2" transform="translate(1788.000000, 467.000000)"> <g id="Group"> <g transform="translate(36.000000, 36.000000) rotate(-90.000000) translate(-36.000000, -36.000000) translate(16.000000, 11.000000)"> <line x1="20" y1="8.8817842e-13" x2="20" y2="50" id="Line" stroke="#979797" stroke-width="2"></line> <path d="M20,50 C20,38.954305 11.045695,30 0,30" id="Path" stroke="#979797" stroke-width="2"></path> <path d="M40,50 C40,38.954305 31.045695,30 20,30" id="Path-Copy" stroke="#979797" stroke-width="2" transform="translate(30.000000, 40.000000) scale(-1, 1) translate(-30.000000, -40.000000) "></path> </g> </g> <circle id="Oval" stroke="#BBB8B4" stroke-width="2" cx="36" cy="36" r="35"></circle> </g> </g> </g> </g></svg>',

  initLoad() {
    slider.config();
  },
  config() {
    if ($(".js-slider").length == 0) return;

    slider.winesSlider();
    slider.singleWineSlider();
    slider.flexbileSlider();
  },
  winesSlider() {

    if ($("#wines .products-list__products").length == 0) return
    if ($(window).outerWidth() > 991) return;

    // we have two slider: wines and pthers (oils, grappe etc)
    // foreach slider we're gonna update the title, which is
    // outside of the slider, as we dont want the title to slide.
    $("#wines .products-list__products").each(function (index) {
      const winesSlider = $(this);
      const titleShown = winesSlider.siblings(".products-list__slide-title");

      // do smth before init slider
      winesSlider.on("init", function () {
        // we set the title by the first slide
        let currentSlideIndex = 0;
        let currentSlide = winesSlider.find(
          '.slick-slide[data-slick-index="' + currentSlideIndex + '"]'
        );
        let slideTitle = currentSlide.find(".product__title").text();

        // update
        titleShown.text(slideTitle);
      });

      // update title on change
      winesSlider.on("afterChange", function (event, slick, currentSlideIndex) {
        let currentSlide = winesSlider.find(
          '.slick-slide[data-slick-index="' + currentSlideIndex + '"]'
        );
        let slideTitle = currentSlide.find(".product__title").text();
        // update
        titleShown.text(slideTitle);
      });

      winesSlider.slick({
        mobileFirst: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        easing: "power4.out",
        speed: 750,
        swipe: true,
        dots: true,
        prevArrow: '<span class="arrow-prev">' + slider.arrow + "</span>",
        nextArrow: '<span class="arrow-next">' + slider.arrow + "</span>",
      });
    });
  },
  flexbileSlider() {


    if ($("#flexible .js-slider").length == 0) return

    // FLEXBILE
    const flexbileSlider = $("#flexible .js-slider ");

    // do smth before init slider
    flexbileSlider.on("init", function () {
      // /azienda/ page.
      // we'll add the translation
      if ($(".top-image__title").text() != "Azienda") return;

      // create caption
      $(".js-slider").append(
        '<p class="slider__caption-wrapper"></span><span class="slider__caption"></span></p>'
      );

      // update caption
      slider.updateCaption(0);
    });

    // update caption on change
    flexbileSlider.on(
      "afterChange",
      function (event, slick, currentSlideIndex) {
        slider.updateCaption(currentSlideIndex);
      }
    );

    flexbileSlider.slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      easing: "power4.out",
      speed: 750,
      arrows: true,
      swipe: true,
      prevArrow: '<span class="arrow-prev">' + slider.arrow + "</span>",
      nextArrow: '<span class="arrow-next">' + slider.arrow + "</span>",
      responsive: [
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 1,
            variableWidth: false,
            centerMode: false,
            dots: false,
          },
        },
      ],
    });
  },
  singleWineSlider() {

    if ($("#single-wine .awards__slider").length == 0) return
    $("#single-wine .awards__slider").slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      easing: "power4.out",
      speed: 750,
      arrows: true,
      swipe: true,
      dots: true,
      prevArrow: '<span class="arrow-prev">' + slider.arrow + "</span>",
      nextArrow: '<span class="arrow-next">' + slider.arrow + "</span>",
      // responsive: [
      //   {
      //     breakpoint: 991,
      //     settings: {
      //       dots: true,
      //     },
      //   },
      // ],
    });
  },
  updateCaption(currentSlideIndex) {
    let currentSlide = $(
      '.slick-slide[data-slick-index="' + currentSlideIndex + '"]'
    ).find(".slider__slide");

    $(".slider__caption").text(currentSlide.data("caption"));
  },
};

export default slider;
