const singleWine = {
  initLoad(){

    singleWine.openProperties()
  },
  openProperties(){

    if ($('#single-wine').length  == 0) return

    const properties = $('#single-wine').find('.properties__wrapper .list-item')
    properties.on('click',function(){

      $(this).find('.list-item__value').slideToggle()
      $(this).toggleClass('active')
    })

  }
}

export default singleWine
