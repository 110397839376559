import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { SplitText } from "gsap/SplitText";
import smoothScroll from "../smoothScroll";
import "slick-carousel";

gsap.registerPlugin(ScrollTrigger);

const homeAnim = {
  squareBoxEffect: "",
  commonEffects: {},
  arrow:
    '<svg width="72px" height="72px" viewBox="0 0 72 72" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"> <title>Group 12 Copy 2</title> <g id="HOMEPAGE" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"> <g id="MARVEL---HOME-1" transform="translate(-1789.000000, -5746.000000)"> <g id="Group-2" transform="translate(1.000000, 5279.000000)"> <g id="Group-12-Copy-2" transform="translate(1788.000000, 467.000000)"> <g id="Group"> <g transform="translate(36.000000, 36.000000) rotate(-90.000000) translate(-36.000000, -36.000000) translate(16.000000, 11.000000)"> <line x1="20" y1="8.8817842e-13" x2="20" y2="50" id="Line" stroke="#979797" stroke-width="2"></line> <path d="M20,50 C20,38.954305 11.045695,30 0,30" id="Path" stroke="#979797" stroke-width="2"></path> <path d="M40,50 C40,38.954305 31.045695,30 20,30" id="Path-Copy" stroke="#979797" stroke-width="2" transform="translate(30.000000, 40.000000) scale(-1, 1) translate(-30.000000, -40.000000) "></path> </g> </g> <circle id="Oval" stroke="#BBB8B4" stroke-width="2" cx="36" cy="36" r="35"></circle> </g> </g> </g> </g></svg>',
  initLoad() {
    if ($("#home").length == 0) return;

    homeAnim.commonEffects = require("./../commonEffects");
    (homeAnim.squareBoxEffect = homeAnim.commonEffects.squareBox.set),
      homeAnim.groupAnims();
  },
  groupAnims() {
    homeAnim.sectionIntroduction();
    homeAnim.sectionExperience();
    homeAnim.sectionWines();
    homeAnim.sectionNews();
  },
  sectionIntroduction() {
    gsap
      .timeline({
        scrollTrigger: {
          trigger: ".introduction__wrapper",
          start: "top top",
          // end: "+=1000px",
          end: "+=30%",
          scrub: true,
          markers: false,
          pinSpacing: true,
          pin: ".introduction__wrapper .parallax-title",
        },
      })
      .fromTo(
        ".introduction__background",
        homeAnim.commonEffects.homeBigImages.set,
        homeAnim.commonEffects.homeBigImages.to
      );

    let startPos = "top top";
    if ($(window).outerWidth() < 767) {
      homeAnim.squareBoxEffect = { scale: 0.9 };
      startPos = "center center";
    }

    gsap
      .timeline({
        scrollTrigger: {
          onComplete: function () {
            smoothScroll.updatePositions();
          },
          trigger: ".introduction__box",
          start: startPos,
          end: "+=30%",
          scrub: true,
          pin: true,
          markers: false,
          pinSpacing: true,
        },
      })
      .fromTo(
        ".introduction__box",
        homeAnim.squareBoxEffect,
        homeAnim.commonEffects.squareBox.to
      )
      .fromTo(
        ".introduction__box .link-simple",
        homeAnim.commonEffects.fadeIn.set,
        homeAnim.commonEffects.fadeIn.to,
        "-=2"
      );
  },
  sectionExperience() {
    gsap
      .timeline({
        scrollTrigger: {
          trigger: ".experience__wrapper",
          start: "top top",
          // end: "+=1000px",
          end: "+=30%",
          scrub: true,
          markers: false,
          pinSpacing: false,
          pin: ".experience__wrapper .parallax-title",
        },
      })
      .fromTo(
        ".experience__background",
        { clipPath: "inset(0 0 0 100%" },
        { clipPath: "inset(0 0 0 0" }
      );

    let startPos = "top top";
    if ($(window).outerWidth() < 767) {
      homeAnim.squareBoxEffect = { scale: 0.9 };
      startPos = "center center";
    }

    gsap
      .timeline({
        scrollTrigger: {
          trigger: ".experience__box",
          start: startPos,
          end: "+=30%",
          scrub: true,
          pin: true,
          markers: false,
          pinSpacing: true,
        },
      })
      .addLabel("start")
      .fromTo(
        ".experience__box",
        homeAnim.squareBoxEffect,
        homeAnim.commonEffects.squareBox.to
      )
      .fromTo(
        ".experience__box .link-circle",
        { opacity: 0 },
        { opacity: 1 },
        "-=.5"
      );
  },
  sectionWines() {
    if ($("#home .wines-slider__slider").length == 0) return;

    // we add 'flexbox' to center things
    // we add it after cuz 'flex' props could break slick slider, so
    // first we set slick slider then we center it
    const homeWinesSlider = $("#home .wines-slider__slider");

    homeWinesSlider
      .slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: true,
        variableWidth: false,
        easing: "power4.out",
        infinite: true,
        speed: 750,
        arrows: true,
        swipe: true,
        prevArrow: '<span class="arrow-prev">' + homeAnim.arrow + "</span>",
        nextArrow: '<span class="arrow-next">' + homeAnim.arrow + "</span>",
        responsive: [
          {
            breakpoint: 991,
            settings: {
              dots: true,
              slidesToShow: 1,
              variableWidth: false,
              centerMode: false,
            },
          },
        ],
      })
      .addClass("flexbox");

    homeWinesSlider.on("swipe", function () {
      currentWine = $(
        ".wines-slider__slider .slick-current .single-wine-circle"
      );
    });

    let currentWine = $(
      ".wines-slider__slider .slick-current .single-wine-circle"
    );

    let arrows = $(".wines-slider__wrapper-inner .slick-arrow");
    arrows.on("click", function () {
      currentWine = $(
        ".wines-slider__slider .slick-current .single-wine-circle"
      );
    });

    currentWine.addClass("hidden-text");

    let clipCustom = "inset(11% 28%)";
    if ($(window).outerWidth() < 767) {
      clipCustom = "inset(11% 8%)";
    }

    let startAt = "top center";
    let endAt = "+=50%";
    let listPos = "0"
    let dotsPos = "15"
    if ($(window).outerWidth() < 991) {
      startAt = "center center";
      endAt = "+=28%";
      listPos = "-20"
      dotsPos = "20%"
    }

    gsap
      .timeline({
        onStart: function () {

          currentWine.removeClass("hidden-text");
          gsap
            .timeline()
            .addLabel("start")
            .fromTo(
              ".wines-slider__slider .slick-current .single-wine-circle",
              {
                scale: 0.85,
              },
              {
                scale: 1,
              },
              "start"
            )
            .fromTo(
              $(".wines-slider__slider .slick-slide")
                .not(".slick-current")
                .find(".single-wine-circle"),
              {
                yPercent: -10,
                opacity: 0,
              },
              {
                yPercent: 0,
                opacity: 1,
              },
              "start+=.5"
            );
        },
        onComplete: function () {
          $(".wines-slider .link-simple").addClass("visible");
        },
        scrollTrigger: {
          onUpdate: function (self) {
            if (self.progress < 0.1 && self.direction == -1) {
              gsap.to(currentWine, { scale: 0.85 });
              currentWine.addClass("hidden-text");
              $(".wines-slider .link-simple").removeClass("visible");
            }
          },
          trigger: ".wines-slider__wrapper",
          start: startAt,
          end: endAt,
          scrub: true,
          markers: false,
          pin: true,
          pinSpacing: false,
        },
      })
      .fromTo(
        ".wines-slider__wrapper",
        { clipPath: clipCustom },
        { clipPath: "inset(0% 0%)" }
      )
      .fromTo(
        ".wines-slider__slider .slick-list",
        {
          yPercent: 0,
        },
        {
          yPercent: listPos,
        },
        "start"
      )
      .fromTo(
        $(".wines-slider__slider .slick-dots"),
        {
          bottom: "15%",
        },
        {
          bottom: dotsPos,
        },
        "start"
      )
      .fromTo(
        $(".wines-slider__slider .slick-dots"),
        {
          opacity: 0,
        },
        {
          opacity: 0.3,
        },
        "start+=.25"
      )
      .fromTo(
        $(".wines-slider .link-simple"),
        homeAnim.commonEffects.fadeIn.set,
        homeAnim.commonEffects.fadeIn.to,
        "start"
      );
  },
  sectionNews() {
    // remaining items
    let newsList = gsap.utils.toArray(".news-block");
    gsap.set(newsList, homeAnim.commonEffects.textContent.set);
    gsap
      .timeline({
        scrollTrigger: {
          trigger: ".news",
          start: "top center",
        },
      })
      .addLabel("start")
      .fromTo(
        ".news__title ",
        homeAnim.commonEffects.bigTitle.set,
        homeAnim.commonEffects.bigTitle.to
      )
      .fromTo(
        newsList,
        homeAnim.commonEffects.textContent.set,
        homeAnim.commonEffects.textContent.to
      );
  },
};

export default homeAnim;
