import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { SplitText } from "gsap/SplitText";
import smoothScroll from "./../smoothScroll";

gsap.registerPlugin(SplitText);

const effectbigTitle = {
  initLoad() {
    // we use class 'big-title' for css props, and 'bigTitleAnim' class for
    // triggering js animations, as this anim could be used on multiple titles,
    // and not in 'big-title' elements only.
    if ($(".bigTitleAnim").length == 0) return;

    const bigTitles = gsap.utils.toArray("#main .bigTitleAnim");

    bigTitles.forEach(function (bigTitle, index) {


      new SplitText(bigTitle, {
        type: "lines,words",
        linesClass: "split-line",
        wordsClass: "split-word",
      });

      let lines = bigTitle.querySelectorAll(".split-line");
      // set negative x alternate to positive
      let newX = -55;
      lines.forEach(function (line) {
        if (line.querySelectorAll(".split-word").length > 0) {
          let wordsInLine = line.querySelectorAll(".split-word");
          gsap.set(line, { position: "relative", opacity: 0, x: newX, y: 0 });
          newX = newX * -1;
        }
      });

      let finalX = 25
      if ($(window).outerWidth() < 768) finalX = 0

      gsap
        .timeline({
          scrollTrigger: {
            trigger: bigTitle,
            start: "-=2700px",
            scrub: true,
            markers: false,
          },
        })
        .to(bigTitle, { opacity: 1 })
        .to(bigTitle.querySelectorAll(".split-line"), {
          opacity: 1,
          x: finalX,
          y: 0,
        });
    });
  },
};

export default effectbigTitle;
