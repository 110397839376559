import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const winesAnim = {
  commonEffects: {},
  initLoad() {
    if ($("#wines").length == 0) return;

    winesAnim.commonEffects = require("./../commonEffects");
    winesAnim.groupAnims();
  },
  groupAnims() {
    winesAnim.sectionList();
  },
  sectionList() {
    // first 3 items
    let firstProducts = gsap.utils.toArray(".product__wrapper").slice(0, 3);
    gsap
      .timeline()
      .addLabel("start")
      .fromTo(
        ".products-list__title ",
        winesAnim.commonEffects.bigTitle.set,
        winesAnim.commonEffects.bigTitle.to,
        "start+=.8"
      )
      .fromTo(
        firstProducts,
        winesAnim.commonEffects.textContent.set,
        winesAnim.commonEffects.textContent.to,
        "start+=0.5"
      );

    // remaining items
    let lastProducts = gsap.utils.toArray(".product__wrapper").slice(3);
    gsap.set(lastProducts, winesAnim.commonEffects.textContent.set);
    lastProducts.forEach((product, index) => {
      gsap
        .timeline({
          scrollTrigger: {
            trigger: product,
            start: "top bottom",
            end: "+=500px",
            markers: false,
            scrub: false,
          },
        })
        .to(product, winesAnim.commonEffects.textContent.to);
    });
  }
};

export default winesAnim;
