const circleLinks = {
  initLoad() {
    circleLinks.countLetters();
  },
  countLetters() {
    // each link..
    $(".link-circle, .single-wine-circle").each(function (index) {
      let CircleLink = $(this);
      let maxLettersInCircle = CircleLink.data("repeat") || 40;
      // if ($(window).outerWidth() >= 1920) maxLettersInCircle = 35;
      // if ($(window).outerWidth() <= 667) maxLettersInCircle = 41;
      let maxLettersPerWord = (maxLettersInCircle / 2) - 1;

      let textContent = CircleLink.find(".text-wrapper text textPath");
      let textContentLength = textContent.text().length;

      if (textContentLength < maxLettersPerWord) {
        // max time word can be used (use ceil in order to have more space, just in case)
        let canRepeatWord = Math.floor(maxLettersInCircle / textContentLength);

        // spaces available in total
        let rest = maxLettersInCircle % (textContentLength * canRepeatWord);

        // space between each word
        let spacesBetweenWords = Math.ceil(rest / canRepeatWord);

        // we have not enough space
        while (rest < canRepeatWord) {
          canRepeatWord--;

          // spaces available in total
          rest = maxLettersInCircle % (textContentLength * canRepeatWord);

          // space between each word
          spacesBetweenWords = Math.floor(rest / canRepeatWord);
        }

        // console.log(
        //   textContent.text() +
        //     "=> " +
        //     maxLettersInCircle +
        //     "/" +
        //     textContentLength +
        //     " ...we can repaat the current word ",
        //   canRepeatWord +
        //     " times and rest " +
        //     rest +
        //     " space " +
        //     spacesBetweenWords
        // );

        // create spacers
        let space = "";
        for (let index = 0; index < spacesBetweenWords; index++)
          space += '<tspan class="spacer">x</tspan>';

        // add space before and after words
        let newText = "";
        for (let index = 0; index < canRepeatWord; index++)
          newText += space + textContent.html();

        // replace content
        textContent.html(newText);
      }
    });
  },
};

module.exports = circleLinks;
