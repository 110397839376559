import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const singleWineAnim = {
  commonEffects: {},
  initLoad() {
    if ($("#single-wine").length == 0) return;

    singleWineAnim.commonEffects = require("./../commonEffects");
    singleWineAnim.groupAnims();
  },
  groupAnims() {
    if ($(window).outerWidth() < 991) return;

    if ($("#single-wine").length == 0) return;
    singleWineAnim.commonEffects = require("./../commonEffects");
    singleWineAnim.sectionHero();
    singleWineAnim.sectionProperties();
  },
  sectionHero() {
    // show / hide things
    gsap
      .timeline({
        scrollTrigger: {
          trigger: ".hero__wrapper .single-wine-circle",
          start: "top top",
          scrub: true,
        },
      })
      .addLabel("start")
      .fromTo(
        ".hero__wrapper .single-wine-circle .text-wrapper",
        { opacity: 1 },
        { opacity: 0, duration: 0.1 },
        "start-=2"
      )
      .fromTo(
        ".hero__wrapper .single-wine-circle",
        { scale: 0.8 },
        { scale: 1 },
        "start"
      );

    gsap
      .timeline({
        scrollTrigger: {
          trigger: ".hero__wrapper .arrow-prev",
          start: "top 45%",
          end: "+=100px",
          markers: false,
          scrub: true,
        },
      })
      .fromTo(
        ".hero__wrapper .arrow-prev, .hero__wrapper .arrow-next",
        {
          opacity: 1,
        },
        {
          opacity: 0,
          duration: 1,
        }
      );

    // pin single wine until bottom
    gsap.timeline({
      scrollTrigger: {
        trigger: ".hero__wrapper",
        endTrigger: ".properties",
        start: "top top",
        end: "bottom center",
        markers: false,
        scrub: true,
        pin: ".single-wine-circle",
        pinSpacing: false,
      },
    });
  },
  sectionProperties() {
    gsap
      .timeline({
        scrollTrigger: {
          trigger: ".properties__wrapper",
          endTrigger: ".properties",
          start: "top top",
          end: "bottom center",
          scrub: true,
        },
      })
      .addLabel("start");

    gsap.timeline({
      scrollTrigger: {
        trigger: ".properties__list-wrapper__description",
        endTrigger: ".properties",
        start: "top top",
        end: "+=500px",
        pin: true,
        pinSpacing: true,
        scrub: true,
      },
    })
    .fromTo(
      ".properties__title-wrapper,.properties__list-wrapper__description",
      { opacity: 0 },
      { opacity: 1 }
    );

    document.querySelectorAll(".list-item").forEach((item, index) => {
      gsap
        .timeline({
          scrollTrigger: {
            trigger: item,
            start: "top bottom",
            end: "+=75%",
            scrub: true,
            markers: false,
          },
        })
        .fromTo(
          item,
          singleWineAnim.commonEffects.textContent.set,
          singleWineAnim.commonEffects.textContent.to
        );
    });

    gsap.timeline({
      scrollTrigger: {
        trigger: ".properties__title-wrapper-inner",
        endTrigger: ".properties",
        start: "top center",
        end: "bottom bottom",
        markers: false,
        scrub: true,
        pin: true,
        pinSpacing: false,
      },
    });
  },
};

export default singleWineAnim;
