import { gsap } from "gsap";
import smoothScroll from "./smoothScroll";

const importers = {
  initLoad() {
    importers.continentList();
  },
  continentList() {
    if ($(".continent__row").length == 0) return;

    if ($(".scrollHere").length > 0) {
      smoothScroll.update(),
        smoothScroll.scrollTo(0, $(".scrollHere").get(0).offsetTop - 350);
    }

    $(".continent__row").on("click", function () {
      let continentRow = $(this);

      importers.openList(continentRow);
    });
  },
  openList(continentRow) {
    if (continentRow.hasClass("opened")) {
      continentRow.removeClass("opened");
      continentRow.find(".continent__data").removeClass("opened");
    } else {
      $(".continent__row.opened").removeClass("opened");
      $(".continent__data.opened").removeClass("opened");
      continentRow.addClass("opened");
      continentRow.find(".continent__data").addClass("opened");
    }

    gsap.set(continentRow.find(".continent__country"), { opacity: 0 });
    gsap.to(continentRow.find(".continent__country"), {
      opacity: 1,
      stagger: 0.15,
      onStart: function () {
        smoothScroll.scrollTo(
          0,
          continentRow.find(".continent__data").get(0).offsetTop - 350
        );
      },
    });
  },
};

export default importers;
