import gsap from "gsap";
import Vivus from "vivus";
import smoothScroll from "./smoothScroll";

const preloader = {
  commonEffects: {},
  canScroll: false,
  hasScrolled: false,
  sectionToMove: $("#main_cont section").eq(0),
  secondSectionToMove: $("#main_cont section").eq(1),
  initReady() {
    preloader.commonEffects = require("./commonEffects");
    preloader.isDebugging();
    preloader.lettersAnimation();
    preloader.getWheel();
  },
  isDebugging(){

    if (window.location.href.indexOf("?debug") > -1) {
      $("#preloader").remove()
    }
  },
  getWheel() {

    preloader.sectionToMove.addClass("will-move");

    $("#preloader .layer").on("click", function () {

      preloader.moveDownPreloader()
    });
    $("#preloader").on("wheel mousewheel touchstart", function (event) {

      preloader.moveDownPreloader(event)
    });
  },
  moveDownPreloader(event = null){
      // animations have finished so we can listen to user scroll now
      if (preloader.canScroll && !preloader.hasScrolled) {

        if (event) event.preventDefault();

        // avoid multiscroll-trigger
        preloader.hasScrolled = true;

        // scroll to top
        smoothScroll.scrollTo(0, 0);

        // u can animate how u want this element. just edit scss single file
        $(".el-will-animate").removeClass("el-will-animate");

        // move out preloader and make first section get in

        const tl = gsap.timeline();

        tl.addLabel("start");
        tl.to("#preloader", { duration: 1, yPercent: -100 }, "start");
        tl.fromTo(
          preloader.sectionToMove,
          { yPercent: 100 },
          { duration: 1, yPercent: 0 },
          "start"
        );
        if ($("#home").length == 0 && $(window).outerWidth() < 991 )
          tl.fromTo(
            preloader.secondSectionToMove,
            { yPercent: 100 },
            { yPercent: 0, duration: 0.8 },
            "start+=.2"
          );
        tl.fromTo(".header", { opacity: 0 }, { opacity: 1 });
      }
  },
  lettersAnimation() {
    const arrowLine = $("#preloader .layer").find(".arrow-counter .arrow-wrap");
    const arrowPercentage = $("#preloader .layer").find(
      ".arrow-counter .percentage"
    );

    const lettersVivus = new Vivus("letters-logo-svg", {
      duration: 350,
      delay: 150,
      animTimingFunction: Vivus.EASE,
    });
    gsap.to(".letters-logo", { opacity: 1 });
    lettersVivus.play(2, function () {
    });

    if (!preloader.isMobile()) {
      gsap.set(arrowLine, { xPercent: -80 });
    }

    gsap.fromTo(arrowLine, { opacity: 0 }, { opacity: 1 });
    gsap
      .timeline({
        onUpdate: function () {
          let newPercentage = parseInt(this.progress() * 100);
          arrowPercentage.text(newPercentage + "%");
        },
        onComplete: function () {
          gsap.to("#letters-logo-svg path", { fill: "#5D5654" });
          preloader.showInspireLayer();
        },
      })
      .addLabel("start")
      .delay(1)
      .to(arrowLine, { duration: 3, xPercent: 0 });
  },
  showInspireLayer() {
    // console.log("INSPIRE STARTED");
    const arrowLine = $("#preloader .layer").find(".arrow-counter .arrow-wrap");

    const lettersLogo = $("#preloader .layer").find(
      ".content-wrapper .letters-logo"
    );
    const inspire = $("#preloader .layer").find(
      ".content-wrapper .inspire-wrapper"
    );
    const arrowDown = $("#preloader .layer").find(
      ".content-wrapper .arrow-down"
    );

    gsap
      .timeline({
        onComplete: function () {
          preloader.canScroll = true;
        },
      })
      .addLabel("start")
      .delay(1.5)
      .to(arrowLine, { opacity: 0 }, "start")
      .to(lettersLogo, { opacity: 0 }, "start")
      .to(inspire, { opacity: 1 }, "start+=.5")
      .fromTo(
        arrowDown,
        preloader.commonEffects.fadeInDown.set,
        preloader.commonEffects.fadeInDown.to,
        "start+=.5"
      );
  },
  isMobile() {
    return $(window).outerWidth() < 991;
  },
};

export default preloader;
