import { gsap } from "gsap";
const menu = {
  menuIsOpened: false,
  initLoad() {
    this.menuResize();
    this.menuInit();
    this.menuHover();
    this.menuClose();
    this.langSelect();
    this.submenuOpen();
  },
  menuInit() {
    // we set menuOpened false the firt time,
    // because after the page change we didn't set it to true already
    menu.menuIsOpened = false;

    let scaledWidth;
    $(window).outerWidth() > 991
      ? (scaledWidth = "250vw")
      : (scaledWidth = "500vw");

    $(".header__burger").on("click", () => {
      if (!menu.menuIsOpened) {
        gsap.set(".header__menu", {
          display: "block",
          opacity: 1,
        });
        gsap.set(
          ".header__menu-inner-top, .header__menu-inner-middle, .header__menu-inner-end, .header__lang",
          {
            duration: 0.2,
            opacity: 1,
          }
        );
        gsap.set(".header__lang", { opacity: 0 });
        gsap.set(".header__primary li", { opacity: 0, y: 10 });
        gsap.set(".header__menu-inner-middle", { borderTop: "none" });
        gsap.set(".header__menu-inner-middle__content h3", { opacity: 0 });
        gsap.set(".header__menu-inner-middle__content li", { opacity: 0 });
        gsap.set(".header__policies", { opacity: 0 });
        gsap.set(".header__ripadellavolta", { opacity: 0 });
        gsap
          .timeline({
            onComplete: function () {
              menu.menuIsOpened = true;
            },
          })
          .addLabel("start")
          .to(".header__menu", {
            duration: 0.7,
            opacity: 1,
          })
          .to(
            ".header__menuBackground",
            {
              duration: 1.5,
              width: scaledWidth,
              height: scaledWidth,
              ease: "Circ.easeOut",
            },
            "start"
          )
          .to(
            ".close-wrapper svg.close",
            {
              opacity: 1,
            },
            "start"
          )
          .to(
            ".header__primary .menu-li",
            { opacity: 1, y: 0, stagger: 0.1 },
            "start+=.2"
          )
          .to(
            ".header__menu-inner-middle__content h3",
            { opacity: 1, stagger: 0.1 },
            "start+=.4"
          )
          .to(".header__lang", { opacity: 1 }, "start+=.6")
          .to(
            ".header__menu-inner-middle__content li",
            { opacity: 1, stagger: 0.1 },
            "start+=.6"
          )
          .to(
            ".header__menu-inner-middle",
            { borderTop: "1px solid #bbb8b4" },
            "start+=.6"
          )
          .to(".header__policies", { opacity: 1 }, "start+=.8")
          .to(".header__ripadellavolta", { opacity: 1 }, "start+=1");
      }
    });
  },
  menuResize() {
    if ($(window).outerWidth() > 667) return;

    $(".is-full-height").height($(window).outerHeight());
  },
  menuHover() {
    if ($(window).outerWidth() < 991) return;

    const secondaryMenu = $(".header__secondary");

    let submenuIsOpen = false;

    $(".header__primary li.has-submenu").on("mouseenter", function () {
      let itemParent = $(this).data("item");
      let parentVoice = secondaryMenu.find(
        ".header__secondary-submenu[data-item-parent=" + itemParent + "]"
      );

      submenuIsOpen = true;
      parentVoice.stop().fadeIn();
    });

    $(".header__primary li.has-submenu").on("mouseleave", function () {
      secondaryMenu.find(".header__secondary-submenu").stop().fadeOut();
    });

    $(".header__secondary-submenu").on("mouseenter", function () {
      $(this).stop().fadeIn();
    });

    $(".header__secondary-submenu").on("mouseleave", function () {
      $(this).stop().fadeOut();
    });

    // let submenuIsOpen = false;
    // let setTime

    // // empty when has not voices
    // $(".header__primary li:not(.has-submenu)").on("mouseenter", function () {
    //   console.log(submenuIsOpen);
    //   if (!submenuIsOpen) {
    //     gsap.to(secondaryMenu.find("li"), {
    //       opacity: 0,
    //     });
    //   }

    // });

    //  $(".header__primary li").on("mouseleave", function () {

    //   clearTimeout(setTime)
    //   setTime = setTimeout(() => {
    //     submenuIsOpen = false;
    //   }, 1000);
    // });

    // // fill submenu panel when has subvoices
    // $(".header__primary li.has-submenu").on("mouseenter", function () {
    //   submenuIsOpen = true;

    //   // we clone the submenu voices to rhe right panel
    //   // as we need to style properly the elements
    //   let submenuVoices = $(this).find(".submenu li").clone();

    //   secondaryMenu.html(submenuVoices);
    //   gsap.to(submenuVoices, {
    //     opacity: 1,
    //   });
    // });

    // fill submenu panel when has subvoices
    // $(".header__secondary").on("mouseenter", function () {

    //   submenuIsOpen = true

    //   console.log('now');

    // });
  },
  menuClose() {
    $(".header__close").on("click", function () {
      if (menu.menuIsOpened) {
        gsap
          .timeline({
            onComplete: function () {
              menu.menuIsOpened = false;

              gsap.timeline().to(".header__menu", {
                opacity: 0,
                duration: 0.5,
                display: "none",
              });
            },
          })
          .addLabel("start")
          .to(
            ".header__menu-inner-top, .header__menu-inner-middle, .header__menu-inner-end, .header__lang",
            {
              duration: 0.2,
              opacity: 0,
            },
            "start"
          )
          .to(
            ".close-wrapper svg.close",
            {
              opacity: 0,
            },
            "start"
          )
          .to(
            ".header__menuBackground",
            {
              duration: 0.7,
              width: "1px",
              height: 0,
              ease: "Circ.easeOut",
            },
            "start"
          );
      }
    });
  },
  langSelect() {
    $(".header__lang").on("click", function () {
      let langsElement = $(this);
      let langsList = langsElement.find("ul");

      // is visible?
      if (langsList.is(":visible"))
        langsElement.removeClass("active"), langsList.slideUp(350);
      else langsElement.addClass("active"), langsList.slideDown(350);
    });
  },
  submenuOpen() {
    if ($(window).outerWidth() > 991) return;

    const backToPrimaryMenu = $(".back-primary-menu");
    const voicesWithSubmenu = $(".header__primary .has-submenu");
    voicesWithSubmenu.find("> a").attr("href", "javascript:;");
    const secondaryMenu = $(".header__secondary");

    $(".header__primary li.has-submenu").on("click", function () {
      let itemParent = $(this).data("item");
      let parentVoice = secondaryMenu.find(
        ".header__secondary-submenu[data-item-parent=" + itemParent + "]"
      );

      gsap
        .timeline({
          onComplete: function () {

            secondaryMenu.stop().fadeIn();
            parentVoice.stop().fadeIn();
          },
        })
        .addLabel('start')
        .to(backToPrimaryMenu,{display:'inline-flex',duration:0})
        .to(".header__primary", { xPercent: -100 }, "start")
        .to(".header__secondary", { xPercent: -100 }, "start")
        .to(".header__menu-inner-middle, .header__menu-inner-end", { opacity:0 }, "start")
        .to(backToPrimaryMenu, { opacity: 1 }, "start")
    });

    // voicesWithSubmenu.on("click", function () {
    //   let submenuVoices = $(this).find(".submenu li").clone();
    //   secondaryMenu.empty().append(submenuVoices);

    //   gsap
    //     .timeline()
    //     .addLabel("start")
    //     .to(".header__primary", { xPercent: -100 }, "start")
    //     .to(
    //       ".header__menu-inner-middle, .header__menu-inner-end",
    //       {
    //         duration: 0,
    //         opacity: 0,
    //       },
    //       "start"
    //     )
    //     .to(".header__secondary", { xPercent: 0, display: "flex" }, "start+=.4")
    //     .to(".back-primary-menu", { opacity: 1, display: "flex" }, "start+=.5")
    //     .to(
    //       submenuVoices,
    //       {
    //         duration: 0.5,
    //         opacity: 1,
    //       },
    //       "-=0.5"
    //     );
    // });

    backToPrimaryMenu.on("click", function () {


      gsap
        .timeline({
          onComplete:function(){

            secondaryMenu.stop().fadeOut();
            $(".header__secondary,.header__secondary-submenu").stop().fadeOut();

          }
        })
        .addLabel('start')
        .to(".header__menu-inner-middle, .header__menu-inner-end", { opacity:1 }, "start")
        .to(".header__secondary", { xPercent: 100 }, "start")
        .to(".header__primary", { xPercent: 0 }, "start")
        .to(backToPrimaryMenu, { opacity: 0 }, "start")


      // gsap
      //   .timeline()
      //   .addLabel("start")
      //   .to(".header__secondary", { xPercent: 120 }, "start")
      //   .to(".back-primary-menu", { opacity: 0 }, "start")
      //   .to(".header__primary", { xPercent: 0 }, "start+=.2");
      // .set(
      //   ".header__secondary,.header__primary",
      //   { display: "none" },
      //   "start+=1"
      // );
    });
  },
};

module.exports = menu;
