const commonEffects = {
  // heroTitles: {
  //   duration: 1.4,
  //   opacity: 1,
  //   scale: 0,
  //   y: 80,
  //   rotationX: 180,
  //   transformOrigin: "0% 50% -50",
  //   ease: "back",
  //   stagger: 0.05
  // },
  squareBox: {
    set: { scale: 0.7},
    to: { scale: 1, duration: 2 },
  },
  homeBigImages: {
    set: {
      // backgroundSize: "100% 170%",
      clipPath: "polygon(0 0, 0 0, 0 100%, 0 100%)",
    },
    to: {
      duration: 5,
      // backgroundSize: "cover",
      clipPath: "polygon(100% 0, 0 0, 0 100%, 100% 100%)",
    },
  },
  images: {
    set: {
      opacity: 0,
      // scale: 1.7,
      clipPath: "polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)",
      duration: 0.3,
      ease: "Power1.out",
    },
    toMiddle: {
      // scale: 1.3,
      opacity: 1,
      clipPath: "polygon(0 0, 70% 0, 70% 100%, 0% 100%)",
      duration: .8,
      ease: "Power1.out",
    },
    to: {
      // scale: 1,
      opacity: 1,
      clipPath: "polygon(0 0, 100% 0, 100% 100%, 0% 100%)",
      duration: 0.5,
      ease: "Power1.out",
    },
  },
  textContent: {
    set: { yPercent: 30, opacity: 0, ease: "Expo.easeOut" },
    to: { yPercent: 0, opacity: 1, ease: "Expo.out", stagger: 0.1, },
  },
  bigTitle: {
    set: { yPercent: 30, opacity: 0, ease: "Expo.easeOut" },
    to: { yPercent: 0, opacity: 1, ease: "Expo.out" },
  },
  photoGrid: {
    set: { yPercent: 50, ease: "Expo.easeOut" },
    to: { yPercent: 0,  opacity: 1, ease: "Expo.out" },
    one: {
      to: { yPercent: -10, opacity: 1},
    },
    two: {
      to: { yPercent: -25,opacity: 1 },
    },
    three: {
      set: { yPercent: 15, ease: "Expo.easeOut" },
      to: { yPercent: -35},
    }
  },
  fadeInDown: {
    set: { y: -40, opacity: 0 },
    to: { opacity: 1, y: 0 }
  },
  fadeIn: {
    set: {opacity: 0 },
    to: { opacity: 1, stagger: 0.1}
  }
};

export default commonEffects;
