const configs = [
  {
    class: "fadeInUpTitle",
    set: { yPercent: 50, opacity: 0 },
    start: "top bottom",
    markers: false,
    effect: {
      delay: .5,
      duration: 1,
      opacity: 1,
      yPercent: -50,
      stagger: { each: 0.1 },
      overwrite: true,
    },
  },,
  {
    class: "fadeInUp",
    set: { yPercent: 50, opacity: 0 },
    start: "top bottom",
    markers: false,
    effect: {
      delay: 0,
      duration: 1,
      opacity: 1,
      yPercent: 0,
      stagger: { each: 0.1 },
      overwrite: true,
    },
  },
  {
    class: "fadeInDown",
    set: { yPercent: 0, opacity: 0 },
    start: "top bottom",
    markers: false,
    effect: {
      opacity: 1,
      yPercent: 50,
      stagger: { each: 0.5 },
      overwrite: true,
    },
  },
  {
    class: "fadeIn",
    set: { opacity: 0 },
    start: "top center",
    markers: false,
    effect: {
      duration: 0.4,
      opacity: 1,
      stagger: { each: 0.15 },
      overwrite: true,
    },
  },
  {
    class: "fadeUp",
    set: { y: 50 },
    start: "top center",
    markers: false,
    effect: { yPercent: -50, stagger: { each: 0.2 }, overwrite: true },
  },
  {
    class: "fadeInLeft",
    set: { xPercent: -100 },
    start: "top 75%",
    markers: false,
    effect: {
      duration: 2,
      xPercent: 0,
      stagger: { each: 0.25 },
      overwrite: true,
    },
  },
  {
    class: "maskFromLeft",
    set: { clipPath: "inset(0 85% 0 0)" },
    start: "top bottom",
    markers: false,
    effect: {
      duration: 4,
      clipPath: "inset(0 0% 0 0)",
      stagger: { each: 0.15 },
      ease: "Power4.easeOut",
      overwrite: true,
    },
  },
  {
    class: "maskFromTop",
    set: { clipPath: "polygon(0 0, 100% 0%, 100% 0, 0 0)" },
    start: "top 75%",
    markers: false,
    effect: {
      duration: 4,
      clipPath: "polygon(0 0, 100% 0%, 100% 100%, 0% 100%)",
      stagger: { each: 0.15 },
      overwrite: true,
    },
  },
  {
    class: "zoomOut",
    set: { scale: 1.2 },
    start: "top 75%",
    markers: false,
    effect: { scale: 1, stagger: { each: 0.15 }, overwrite: true },
  },
  {
    class: "rotate",
    set: { rotate: 0 },
    repeat: -1,
    repeatDelay: 1,
    start: "top 75%",
    markers: false,
    effect: {
      duration: 1,
      rotate: 360,
      stagger: { each: 0.15 },
      overwrite: true,
    },
  },
];

export default configs;
