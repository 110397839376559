import LazyLoad from 'vanilla-lazyload';

const lazy = {
	initLoad() {
		this.initLazyLoad();
	},

	initLazyLoad() {
    	const ll = new LazyLoad({
	        elements_selector: '.lazy',
	        use_native: true
	    })
	},
}

export default lazy;
